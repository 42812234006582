export const email = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'validation.email' : undefined;

export const required = (value: any) => {
  if (value || typeof value === 'number' || typeof value === 'boolean') {
    return undefined;
  } else {
    return 'validation.required';
  }
};

export const checkboxRequired = (value: any) => {
  if (Array.isArray(value)) {
    let checker = (arr: any[]) => arr.every(v => v === false);
    if (checker(value)) {
      return 'validation.required';
    }
  }
  return undefined;
};

export const eightCharMinimum = (value: any) =>
  value && !/^.{8,}$/.test(value) ? 'validation.eightCharMinimum' : undefined;

export const twoChar = (value: any) => (value && !/^.{2,2}$/.test(value) ? 'validation.twoChar' : undefined);

export const alphanumericOnly = (value: any) =>
  value && !/^[a-zA-Z0-9]*$/.test(value) ? 'validation.alphanumericOnly' : undefined;

export const capitalAlphanumericOnly = (value: any) =>
  value && !/^[A-Z0-9]*$/.test(value) ? 'validation.capitalAlphanumericOnly' : undefined;

export const alphanumeric = (value: any) =>
  value && !/^(([a-zA-Z]+[0-9]+)|([0-9]+[a-zA-Z]+))[a-zA-Z0-9_]*$/.test(value) ? 'validation.alphanumeric' : undefined;

export const numericOnly = (value: any) => (value && !/^[0-9]*$/.test(value) ? 'validation.numericOnly' : undefined);

export const phoneWithoutContryCode = (value: any) =>
  value && !/^[1-9][0-9]{7,12}/.test(value) ? 'validation.phone' : undefined;

export const emailLines = (value: string) => {
  if (value) {
    const parsedValue = value.split('\n');
    for (const pv of parsedValue) {
      if (email(pv) !== undefined || (pv === '' && parsedValue.length > 1)) {
        return 'validation.emailLines';
      }
    }
  }
  return undefined;
};

export const username = (value: any) =>
  value && !/^[A-Za-z0-9]+(?:[_.-][A-Za-z0-9]+)*$/.test(value) ? 'validation.username' : undefined;

export const uniqueLines = (value: string) => {
  if (value) {
    const parsedValue = value.split('\n');
    for (const v of value.split('\n')) {
      if (parsedValue.filter(pv => pv === v).length > 1) {
        return 'validation.uniqueLines';
      }
    }
  }
  return undefined;
};

export const confirmPassword = (value: any, allValues: any) => {
  const testValue = value === undefined ? '' : value;
  return testValue === allValues.password ? undefined : 'validation.passwordNotMatch';
};

export const nonNegativeInt = (value: any) => {
  if (isNaN(Number(value))) {
    return 'validation.invalidNumber';
  }
  return Number(value) < 0 ? 'validation.nonNegative' : '';
};

export const isLatitude = (value: any) => {
  if (isNaN(Number(value))) {
    return 'validation.invalidNumber';
  }
  return Number(value) < -90 || Number(value) > 90 ? 'validation.latitude' : '';
};

export const isLongitude = (value: any) => {
  if (isNaN(Number(value))) {
    return 'validation.invalidNumber';
  }
  return Number(value) < -180 || Number(value) > 180 ? 'validation.longitude' : '';
};

export const maxLength2 = (value: any) => {
  return value.length > 2 ? 'validation.maxLength.2' : '';
};

export const maxLength12 = (value: any) => {
  return value.length > 12 ? 'validation.maxLength.12' : '';
};

export const notEmptyArray = (value: any) => {
  return !value || !value.length ? 'validation.notEmptyArray' : '';
};

export const greaterThanZero = (value: any) => {
  if (isNaN(Number(value))) {
    return 'validation.invalidNumber';
  }
  return value <= 0 ? 'validation.greaterThanZero' : '';
};

export const greaterThanOne = (value: any) => {
  if (isNaN(Number(value))) {
    return 'validation.invalidNumber';
  }
  return value <= 1 ? 'validation.greaterThanOne' : '';
};

export const lengthMustFour = (value: any) => {
  return value.length !== 4 ? 'validation.length.4' : '';
};

export const maxLength32 = (value: any) => {
  return value.length > 32 ? 'validation.maxLength.32' : '';
};

export const maxNumber = (param: number) => (value: any) => {
  if (isNaN(Number(value))) {
    return 'validation.invalidNumber';
  }
  return value > param ? 'validation.maxNumber' : '';
};

export const minCup = (value: any, allValues: any) => {
  if (!allValues.mediumMaxCup) {
    return undefined;
  }
  return value < allValues.mediumMaxCup ? undefined : 'validation.minMaxCup';
};

export const maxCup = (value: any, allValues: any) => {
  if (!allValues.mediumMinCup) {
    return undefined;
  }
  return value > allValues.mediumMinCup ? undefined : 'validation.minMaxCup';
};

export const lowMediumHigh = (value: any, allValues: any) => {
  if (!allValues.low || !allValues.medium || !allValues.high) {
    return undefined;
  }

  return allValues.low <= allValues.medium && allValues.medium <= allValues.high
    ? undefined
    : 'validation.lowMediumHigh';
};

export const maxLength15 = (value: any) => (value.length > 15 ? 'validation.maxLength.15' : undefined);

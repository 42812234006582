import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { FormattedMessage } from 'react-intl';
import { Field, Validator } from 'redux-form';
import { FieldIndicators } from 'src/app/components/forms/FieldIndicators';
import { EyeIcon, EyeSlashIcon } from '../../Icons';

interface PasswordInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  validate?: Validator | Validator[];
  placeholder?: string;
  hideValidMarker?: boolean;
}

@withStyles(require('./PasswordInput.scss'))
export class PasswordInput extends React.Component<PasswordInputProps> {
  render() {
    return (
      <Field
        label={this.props.placeholder}
        component={passwordInputField}
        name={this.props.name}
        validate={this.props.validate}
        {...this.props}
      />
    );
  }
}

const passwordInputField = ({
  hideValidMarker,
  input,
  label,
  placeholder,
  disabled,
  meta: { touched, error, warning }
}: any) => {
  const [inputType, setInputType] = React.useState<'password' | 'text'>('password');

  const toggleInputType = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <div className="PasswordInput__container">
      <FormattedMessage id={label}>
        {labelTxt => (
          <FormattedMessage id={placeholder}>
            {// tslint:disable-next-line:jsx-no-multiline-js
            placeholderTxt => (
              <>
                <label className="TextInput__label">{labelTxt}</label>
                <input
                  className={'PasswordInput ' + (touched && error && 'PasswordInput--error')}
                  placeholder={placeholderTxt}
                  type={inputType}
                  disabled={disabled}
                  {...input}
                />
                {inputType === 'password' && <EyeIcon className="PasswordInput__eye-icon" onClick={toggleInputType} />}
                {inputType === 'text' && <EyeSlashIcon className="PasswordInput__eye-icon" onClick={toggleInputType} />}
              </>
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
      {touched && error && <FieldIndicators indicator="error" />}
      {touched && error && (
        <div className="PasswordInput__error">
          <FormattedMessage id={error} />
        </div>
      )}
      {hideValidMarker === undefined ? touched && !error && <FieldIndicators indicator="valid" /> : ''}
    </div>
  );
};

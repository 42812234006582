import * as React from 'react';
import { GearIcon } from 'src/app/components/Icons';
import { CONFIGURATION, MASTER_CONFIG } from 'src/app/constants/route';
import { SidebarItem } from '../sidebar';

const DELIVERY_OPERATIONAL_SIDEBAR: SidebarItem = {
  authorityIds: [CONFIGURATION.requiredAuthority!],
  iconName: 'ic-configuration',
  id: 'configuration',
  labelId: 'sidebar.deliveryOperational',
  url: CONFIGURATION.path
};

const CONFIG_SIDEBAR: SidebarItem = {
  authorityIds: [CONFIGURATION.requiredAuthority!],
  iconName: 'ic-user',
  id: 'user',
  labelId: 'sidebar.driverFee',
  url: MASTER_CONFIG.path
};

export const CONFIGURATION_SIDEBAR: SidebarItem = {
  authorityIds: [CONFIGURATION.requiredAuthority!, CONFIGURATION.requiredAuthority!],
  children: [DELIVERY_OPERATIONAL_SIDEBAR, CONFIG_SIDEBAR],
  icon: <GearIcon />,
  iconName: 'ic-configuration',
  id: 'configuration',
  labelId: 'sidebar.configuration',
};
